<template>
  <LargeLayout>
    <h1 class="text-success text-center mb-3">
      {{ $t("carousel.customise carousel") }}
    </h1>
    <div class="d-none d-lg-flex">
      <!-- draggable -->
      <b-overlay :show="isUploading" spinner-variant="success">
        <div class="drag_images">
          <Draggable
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false"
            class="customise__wrapper"
          >
            <div
              v-for="(element, index) in listCarousel"
              :key="index"
              style="width: 100%; height: 100%"
            >
              <div class="posititon-relative" style="width: 100%; height: 100%">
                <b-button
                  class="close-btn position-absolute"
                  @click="deleteItem(element, index)"
                >
                  <i class="fas fa-times"></i>
                </b-button>
                <img
                  :src="element.src"
                  alt="image"
                  class="w-100 customiseCarousel__image"
                />
              </div>
            </div>
          </Draggable>

          <div class="mt-4">
            <input
              ref="uploadImage"
              type="file"
              accept="image/*"
              multiple
              @change="processFiles($event)"
            />
          </div>
        </div>
      </b-overlay>
    </div>
    <p class="mt-3">{{ $t("carousel.file-extension") }}</p>
    <!-- Customise Carousel for mobiles -->
  </LargeLayout>
</template>

<script>
import LargeLayout from "@/layout/main/LargeLayout";
import Draggable from "vuedraggable";
import axiosIns from "@/libs/axiosConfig";
export default {
  components: { LargeLayout, Draggable },
  data() {
    return {
      dragging: false,
      url: process.env.VUE_APP_API_URL,
      files: [],
      isUploading: false,
    };
  },
  computed: {
    listCarousel() {
      return this.$store.getters.getCarousels.map((image) => {
        return {
          src: this.url + image,
        };
      });
    },
  },
  methods: {
    processFiles(event) {
      this.isUploading = true;
      let files = event.target.files;
      let formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      axiosIns
        .post("/imgs/carousel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          response.data.files_url.forEach((file) => {
            this.$store.commit("ADD_CAROUSEL", file);
          });
          this.$refs.uploadImage.value = null;
          this.isUploading = false;
        })
        .catch(() => {
          console.log("something went wrong!!");
          this.$refs.uploadImage.value = null;
          this.isUploading = false;
        });
    },
    deleteItem(element, index) {
      let image = element.src.split("/")[5];
      axiosIns
        .post("/deleteFile", {
          images: [image],
          type: "carousel",
        })
        .then((res) => {
          if (res.data.removed) {
            this.$store.commit("DELETE_CAROUSEL", image);
          } else {
            console.log("File Not Found");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.$store.dispatch("fetchCarousels");
  },
};
</script>

<style lang="scss" scoped>
.customiseCarousel__image {
  width: 100%;
  height: 100%;
  max-height: 110px;
  object-fit: cover;
}

@media screen and (min-width: 1024px) {
  .customise__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
  }
}
</style>
